import Container from '@/components/Container';
import PageComponent from '@/components/PageComponent';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect } from 'react';

const AnniversaryProject: React.FC<
  PageProps<GatsbyTypes.GiftListPageQuery>
> = ({ location }) => {
  const loadPackage = useCallback(() => {
    /*
    SaveLifeControllerService.getSaveLifeListUsingGet()
      .then(({ data }) => setPack([...data]))
      .catch((e) => console.error(e));
      */
  }, []);

  useEffect(() => loadPackage(), []);

  return (
    <LayoutWithTitle
      location={location}
      title="기념기부"
      description="for every child, inspired gifts"
    >
      <Section className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex">
              <h2>
                <Tit size="s1">
                  <PageComponent id="title1">기념기부</PageComponent>
                </Tit>
              </h2>
              <div>blank page</div>
            </div>
          </SectionHeader>
        </Container>
      </Section>
    </LayoutWithTitle>
  );
};

export default AnniversaryProject;
